<template lang="pug">
section.sidemenu(v-bind:class="{ 'assistant-menu': !assistantStore.active && assistantStore.enabled }")
  AssistantConsole(v-if="assistantStore.enabled")
  aside.sliver
    div
      div.brand
        //- img(src='~assets/brand/kershuffle-icon.svg')
        svg(width='100%' height='100%' viewbox='0 0 40 43' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' xml:space='preserve' xmlns:serif='http://www.serif.com/' style='fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2;')
          g(transform='matrix(0.0914433,0,0,-0.0914433,-76.4582,202.913)')
            path(d='M836.124,2219L836.124,2053L1001.51,2219L836.124,2219Z' style='fill:rgb(247,145,29);')
          g(transform='matrix(0.0914433,0,0,0.0914433,-76.4582,-160.671)')
            path(d='M836.124,2219L836.124,2053L1001.51,2219L836.124,2219Z' style='fill:rgb(61,156,251);')
          g(transform='matrix(0.0465962,0,0,-0.0465962,-73.5858,61.0666)')
            path(d='M2134.66,404L2437.01,404L1832.56,1008.45L1681.39,857.275L2134.66,404Z' style='fill:rgb(247,145,29);')
          g(transform='matrix(0.0465962,0,0,0.0465962,-73.5858,-18.8249)')
            path(d='M2134.66,404L2437.01,404L1832.56,1008.45L1681.39,857.275L2134.66,404Z' style='fill:rgb(61,156,251);')

      //- AssistantIcon(v-if="$store.state.assistant.enabled && !brandOnly")
      div.toolbar(v-if="!brandOnly")
        //- a.toolbar__item(v-for="(item, index) in menu" :href="`${item.link}`" :key="`menu-${index}`")
        nuxt-link.toolbar__item(:to="`/admin/`" v-if="userStore.account.role === 'administrator'")
          o-icon(icon='security')
          p Admin
        nuxt-link.toolbar__item(v-for="(item, index) in menu" :to="`${item.link}`" :key="`menu-${index}`")
          o-icon(:icon='item.icon')
          p {{ item.title }}
        //- .toolbar__item(v-for="item in menu" v-on:click="$router.push({path: item.link})")
        //-   b-icon(:icon='item.icon')
        //-   p {{ item.title }}
    div
      AssistantIcon(v-if="assistantStore.enabled && !brandOnly")
      //- div.avatar(v-if="!brandOnly" v-on:click="router.push({path: '/profile'})")
      div.avatar(v-if="!brandOnly" v-on:click="navigateTo('/profile')")
        Avatar
</template>
<script setup>
import { mapStores } from "pinia";
import { useAssistantStore } from "~/stores/assistant";
// import { useProgrammatic } from "@oruga-ui/oruga-next";
// const { oruga } = useProgrammatic();
import { useOruga } from "@oruga-ui/oruga-next";
const oruga = useOruga();
import { useUserStore } from "~/stores/user";
const assistantStore = useAssistantStore();
const userStore = useUserStore();
await userStore.getProfile();
const { token, data, getSession } = useAuth();
const router = useRouter();
const route = useRoute();

defineProps({
  brandOnly: {
    type: Boolean,
    default: false,
  },
});

const menu = ref([
  {
    title: "Websites",
    // icon: 'newspaper-variant-outline',
    // icon: 'spider-web',
    // icon: 'cube-outline',
    icon: "view-dashboard-outline",
    link: "/websites/",
  },
  // {
  //   title: 'People',
  //   // icon: 'access-point',
  //   // icon: 'bullhorn-outline',
  //   icon: 'face',
  //   link: '/people/',
  // },
  {
    title: "Email",
    icon: "email-outline",
    link: "/email/",
  },
  {
    title: "Media",
    icon: "file-image-outline",
    link: "/media/",
  },
  {
    title: "Brand",
    icon: "palette-outline",
    link: "/brand/",
  },
  {
    title: "Forms",
    icon: "form-select",
    link: "/forms/",
  },
  {
    title: "Sell",
    // icon: "currency-usd",
    icon: "cart-outline",
    link: "/sell/",
  },
  {
    title: "Account",
    icon: "account-group-outline",
    link: "/account/",
  },
  // {
  //   title: 'Billing',
  //   icon: 'credit-card-outline',
  //   link: '/billing/',
  // },
  // {
  //   title: 'Link Tree',
  //   icon: 'tree-outline',
  //   link: '/link/',
  // },
  // {
  //   title: 'Projects',
  //   icon: 'flask-empty-outline',
  //   link: '/projects/',
  // },
  // {
  //   title: 'Accounts',
  //   icon: 'briefcase-outline',
  //   link: '/account/',
  // },
]);
</script>
<!-- <script>
import { mapStores } from "pinia";
import { useAssistantStore } from "~/stores/assistant";
import { useProgrammatic } from "@oruga-ui/oruga-next";

export default {
  setup() {
    const { token, data, getSession } = useAuth();
    const { oruga } = useProgrammatic();
    const router = useRouter();
    const route = useRoute();
    return {
      oruga,
      router,
    };
  },
  props: {
    brandOnly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      optionOne: false,
      optionTwo: false,
      optionThree: false,
      optionFour: false,
      activeIndex: undefined,
      menu: [
        {
          title: "Brand",
          icon: "palette-outline",
          link: "/brand/colors",
        },
        {
          title: "Websites",
          // icon: 'newspaper-variant-outline',
          // icon: 'spider-web',
          // icon: 'cube-outline',
          icon: "view-dashboard-outline",
          link: "/websites/",
        },
        // {
        //   title: 'People',
        //   // icon: 'access-point',
        //   // icon: 'bullhorn-outline',
        //   icon: 'face',
        //   link: '/people/',
        // },
        {
          title: "Forms",
          icon: "form-select",
          link: "/forms/",
        },
        // {
        //   title: 'Email',
        //   icon: 'email-outline',
        //   link: '/email/',
        // },
        {
          title: "Sell",
          icon: "currency-usd",
          // icon: 'cart-outline',
          link: "/sell/",
        },
        {
          title: "Media",
          icon: "file-image-outline",
          link: "/media/",
        },
        {
          title: "Account",
          icon: "account-group-outline",
          link: "/account/",
        },
        // {
        //   title: 'Billing',
        //   icon: 'credit-card-outline',
        //   link: '/billing/',
        // },
        // {
        //   title: 'Link Tree',
        //   icon: 'tree-outline',
        //   link: '/link/',
        // },
        // {
        //   title: 'Projects',
        //   icon: 'flask-empty-outline',
        //   link: '/projects/',
        // },
        // {
        //   title: 'Accounts',
        //   icon: 'briefcase-outline',
        //   link: '/account/',
        // },
      ],
    };
  },
  computed: {
    ...mapStores(useAssistantStore),
  },
};
</script> -->

<style lang="scss">
.sidemenu {
  display: flex;
  position: fixed;
  top: 0;
  bottom: 0;
  width: rempx(91);
  z-index: 9;
  transition: 300ms all ease-in-out;
  &.assistant-menu {
    transform: translateX(rempx(-800));
  }
  .sliver {
    min-height: 100vh;
    min-width: rempx(91);
    background: #333;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    // @extend %gradient-fill-vert;
    .brand {
      text-align: center;
      padding-top: rempx(20);
      padding-bottom: rempx(20);
      // background: #f6f9fc;
      // background: #fff;
      // background: #000;
      svg {
        max-width: rempx(40);
        max-height: rempx(42);
      }
      img {
        width: 100%;
        max-width: rempx(40);
      }
    }
    .toolbar {
      color: #fff;
      &__item {
        padding: rempx(20) rempx(10);
        text-align: center;
        cursor: pointer;
        display: block;
        color: #fff;
        p {
          visibility: hidden;
          margin-top: rempx(6);
          font-size: rempx(12);
          text-transform: uppercase;
          letter-spacing: rempx(2);
        }
        &:hover {
          background: $violet;
          p {
            visibility: visible;
          }
        }
        &.router-link-active {
          background: #555;
          p {
            visibility: visible;
          }
        }
      }
    }
    .avatar {
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: rempx(10);
      // > div {
      //   margin: rempx(20) auto;
      //   &:hover {
      //     outline: #fff solid 3px;
      //   }
      // }
    }
  }
}
.main {
  flex: 3;
  overflow: hidden;
  // padding-bottom: rempx(40);
}
</style>
